
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import PageUtil from "@/utils/page_util";
import {IAdminInfo} from "@/models/user_models";
import ApiAdmin from "@/request/apis/api_admin";
import {IAdminRoleDetail} from "@/models/menu_model";

interface IState {
  list: IAdminInfo[],
  total: number;
  loading: boolean;
  searches: any
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '账号',
    dataIndex: 'account',
    key: 'account',
  },
  {
    title: '角色',
    dataIndex: 'roles',
    key: 'roles',
  },
  // {
  //   title: '头像',
  //   dataIndex: 'avatar',
  //   key: 'avatar',
  // },
  {
    title: '真实姓名',
    dataIndex: 'name',
    key: 'name',
  },
  // {
  //   title: '手机号',
  //   dataIndex: 'mobile',
  //   key: 'mobile',
  // },
  {
    title: '状态',
    dataIndex: 'disable',
    key: 'disable',
  },
  {
    title: '上次登录',
    dataIndex: 'login_time',
    key: 'login_time',
  },
  {
    title: '上次修改',
    dataIndex: 'update_time',
    key: 'update_time',
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 160,
    fixed: "right"
  }
];

export default defineComponent({
  name: 'AdminList',
  components: {ImageView},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      category: [],
      subCategory: [],
      editItem: undefined,
      searches: {
        keywords: '',
        pid: '',
        cid: '',
        c_id: '',
        is_recommend: '',
        type: '',
        is_show: '',
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize
      }
    });

    const getImageUrl = (url: string) => {
      if (url.indexOf("http") >= 0) return url;
      return "http://jjyyimage.ynjjyy.cn/" + url;
    }


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiAdmin.getAdminList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.lists || []
      }
    }

    const initMenu = async() => {
      const params = {}
      const res = await ApiAdmin.initializationMenu(params)
      state.loading = false
      if (res && res.error_code === 0) {
        message.success(res.msg)
      }else{
        message.warning(res.msg)
      }
    }

    // 添加或修改管理员
    const addOrUpdateAdmin = async (item?: IAdminInfo) => {
      let params = {}
      if (item) {
        params = {id: item.id, data: JSON.stringify(item)}
      }
      router.push({path: '/setting/admin-set', query: params}).then()
    };

    // 更改启用禁用状态
    const changeAdminStatus = async (item: IAdminInfo) => {
      state.loading = true
      item.password = "";
      item.disable = item.disable ? 0 : 1;
      item.role_ids = item.roles?.map(item => {
        return (item.role as IAdminRoleDetail).id
      }) as number[] || [];
      const res = await ApiAdmin.updateAdminInfo(item)
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success(res.msg)
          return
        }
        message.error(res.msg)
      }
    }

    // 详情
    const goDetail = (item: IAdminInfo) => {
      router.push({path: '/setting/admin-set', query: {id: item.id, data: JSON.stringify(item)}}).then()
    };

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      if (key === 'keywords') {
        for (let key in state.searches) {
          state.searches[key] = ''
        }
      } else {
        state.searches.keywords = ''
        if (key === 'pid') {
          state.searches.cid = value
          state.searches.c_id = ''
          if (value) {
            // state.subCategory = state.category.find(item => item.id === value)?.children || []
          } else {
            // state.subCategory = []
          }
        }
        if (key === 'c_id') {
          if (value) {
            state.searches.cid = value
          } else {
            state.searches.cid = state.searches.pid
          }
        }
      }
      state.searches[key] = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      // state.subCategory = []
      getList()
    }

    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      goDetail,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      getImageUrl,
      addOrUpdateAdmin,
      changeAdminStatus,
      initMenu
    }
  }
})
